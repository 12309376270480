:root {
	--scrollbar-track-color: #ff6347;
	--scrollbar-thumb-color: #ffffff;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

::-webkit-scrollbar-track {
	background-color: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-thumb {
	background: #f1f3f4;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollbar-track-color);
}
